import { keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import { debounce } from "lodash-es"
import React, {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react"
import { usePrefs } from "./PrefsContext"

const blink = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const CursorElement = styled.div<{ isSmooth: boolean }>`
  position: absolute;
  transform: translateX(-0.07em);
  border-right: 0.08em solid currentColor;
  height: 1em;
  animation-name: ${blink};
  animation-duration: 1000ms;
  top: 0;
  left: 0;
  ${({ isSmooth }) => isSmooth && `transition: transform 150ms ease-out;`}
`

export function Cursor({
  cursorTargetRef,
}: {
  cursorTargetRef: RefObject<HTMLSpanElement>
}) {
  const { prefs } = usePrefs()
  const cursorRef = useRef<HTMLDivElement>(null)

  const recalculateCursorPosition = useCallback(() => {
    if (!cursorRef.current) return

    const x =
      (cursorTargetRef.current?.offsetLeft || 0) +
      (cursorTargetRef.current?.offsetWidth || 0)
    const y = cursorTargetRef.current?.offsetTop || 0

    cursorRef.current.style.transform = `translate(${x}px, ${y}px)`
    cursorRef.current.style.height =
      cursorTargetRef.current?.offsetHeight + "px"
  }, [cursorTargetRef])

  useLayoutEffect(recalculateCursorPosition)

  // Adjust position of cursor on window resize
  // TODO: Better to adjust based on container size change in case container changes without being caused by window resize
  useEffect(() => {
    const debounced = debounce(recalculateCursorPosition, 500)
    window.addEventListener("resize", debounced)
    return () => window.removeEventListener("resize", debounced)
  }, [recalculateCursorPosition])

  useEffect(() => {
    const $cursor = cursorRef.current
    const id = window.setTimeout(() => {
      if ($cursor) {
        $cursor.style.animationIterationCount = "infinite"
      }
    }, 700)

    return () => {
      window.clearTimeout(id)
      if ($cursor) {
        $cursor.style.animationIterationCount = ""
      }
    }
  })

  return <CursorElement isSmooth={prefs.smoothCursor} ref={cursorRef} />
}
