import React, { FC, forwardRef, useEffect, useRef } from "react"
import { Cursor } from "./Cursor"
import { head, tail } from "lodash-es"
import styled from "@emotion/styled"
import { useColorModeValue } from "@chakra-ui/color-mode"
import { Box, BoxProps } from "@chakra-ui/layout"
import { usePrefs } from "./PrefsContext"

const commonWordProps: BoxProps = {
  marginRight: "0.5em",
}

const Faded = styled.span`
  opacity: 0.6;
`

const WrongSub: FC = ({ children }) => {
  const wrongColor = useColorModeValue("red.600", "red.200")
  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="1.50em"
        left={0}
        fontSize="sm"
        color={wrongColor}
      >
        {children}
      </Box>
    </Box>
  )
}

const TypedWord: FC<{ wanted: string; actual: string }> = ({
  wanted,
  actual,
}) => {
  const { prefs } = usePrefs()

  const isCorrect = wanted.startsWith(actual)

  return (
    <Box {...commonWordProps}>
      {prefs.showErrorsWhileTyping && !isCorrect && (
        <WrongSub>{actual}</WrongSub>
      )}
      <Faded>{wanted}</Faded>
    </Box>
  )
}

type CurrentWordProps = { wanted: string; actual: string }
const CurrentWord = forwardRef<HTMLSpanElement, CurrentWordProps>(
  ({ wanted, actual }, ref) => {
    const { prefs } = usePrefs()
    // const restOfActiveWord = wanted.slice(actual.length)

    const startOfWanted = wanted.slice(0, actual.length)
    const endOfWanted = wanted.slice(actual.length)

    const correctColor = useColorModeValue("gray.600", "gray.400")
    const wrongColor = useColorModeValue("red.600", "red.200")

    const isCorrect = wanted.startsWith(actual)

    return (
      <Box {...commonWordProps}>
        {prefs.showErrorsWhileTyping && !isCorrect && (
          <WrongSub>{actual}</WrongSub>
        )}
        <Faded as="span" ref={ref}>
          {startOfWanted}
        </Faded>
        {endOfWanted}
      </Box>
    )
  }
)

const WantedWord: FC = ({ children }) => (
  <Box {...commonWordProps}>{children}</Box>
)

const lineHeight = "1.4em"
const WordsWrapper = styled.div`
  overflow: hidden;
  font-size: 200%;
  font-weight: 500;
  line-height: ${lineHeight};
  height: calc(${lineHeight} * 2);
  margin-left: -3px;
`

const WordsWrapperInner = styled.div`
  padding-left: 3px;
  position: relative; /* relative because Cursor is absolutely positioned */
  display: flex;
  flex-wrap: wrap;
  transition-timing-function: ease;
  transition-property: transform;
  transition-duration: 150ms;
`

export function Words({
  typedWords,
  activeWord,
  wantedWords,
  wantedWordI,
  wantedCharI,
}: {
  typedWords: string[]
  activeWord: string
  wantedWords: string[]
  wantedWordI: number
  wantedCharI: number
}) {
  const wordsWrapperInnerRef = useRef<HTMLDivElement>(null)
  const activeWordRef = useRef<HTMLSpanElement>(null)

  const restOfWantedWords = wantedWords.slice(wantedWordI)

  useEffect(() => {
    if (!wordsWrapperInnerRef.current) return
    wordsWrapperInnerRef.current.style.transform = `translateY(-${activeWordRef.current?.offsetTop}px)`
  })

  return (
    <>
      <WordsWrapper>
        <WordsWrapperInner ref={wordsWrapperInnerRef}>
          {typedWords.map((w, i) => (
            <TypedWord key={w + i} wanted={wantedWords[i]} actual={w} />
          ))}
          <CurrentWord
            ref={activeWordRef}
            wanted={head(restOfWantedWords) || ""}
            actual={activeWord}
          />
          {tail(restOfWantedWords).map((w, i) => (
            <WantedWord key={w + i}>{w}</WantedWord>
          ))}
          <Cursor cursorTargetRef={activeWordRef} />
        </WordsWrapperInner>
      </WordsWrapper>
    </>
  )
}
