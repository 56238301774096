import React, { FC, useCallback, useContext, useEffect, useState } from "react"
import { config } from "../../config"
import { PrefsType } from "../../types"
import { useAuthenticated } from "../auth/Authenticated"

type PrefsContextType = {
  isLoading: boolean
  prefs: PrefsType
  resetPrefs: () => void
  updatePrefs: (prefs: Partial<PrefsType>) => void
}

const defaultPrefs = config.defaultPrefs

export const defaultPrefsContextValue = {
  prefs: defaultPrefs,
  isLoading: false,
  resetPrefs: () => {
    throw new Error("Not defined")
  },
  updatePrefs: () => {
    throw new Error("Not defined")
  },
}

const PrefsContext = React.createContext<PrefsContextType>(
  defaultPrefsContextValue
)

function usePrefsInner() {
  const { userDoc } = useAuthenticated()
  const [prefs, setPrefs] = useState<PrefsType>(defaultPrefs)
  const [isLoading, setIsLoading] = useState(true)

  // Load and watch prefs for changes
  useEffect(() => {
    const unsubscribe = userDoc().onSnapshot(
      { includeMetadataChanges: true },
      doc => {
        if (doc.data()?.prefs) {
          setPrefs({ ...defaultPrefs, ...doc.data()?.prefs })
        }
        setIsLoading(doc.metadata.hasPendingWrites)
      }
    )
    return unsubscribe
  }, [userDoc])

  // Update prefs via Firebase, which will then populate the React state
  const updatePrefs = useCallback(
    (prefs: Partial<PrefsType>) => {
      setIsLoading(true)
      userDoc().set({ prefs }, { merge: true })
    },
    [userDoc]
  )

  const resetPrefs = useCallback(() => {
    userDoc().set({ prefs: defaultPrefs }, { merge: true })
  }, [userDoc])

  return {
    isLoading,
    // error,
    prefs,
    updatePrefs,
    resetPrefs,
  }
}

export const PrefsProvider: FC = ({ children }) => {
  const obj = usePrefsInner()

  return <PrefsContext.Provider value={obj}>{children}</PrefsContext.Provider>
}

// export const DefaultPrefsContext: FC = ({ children }) => {
//   return (
//     <PrefsContext.Provider value={defaultPrefsContext}>
//       {children}
//     </PrefsContext.Provider>
//   )
// }

export function usePrefs() {
  const obj = useContext(PrefsContext)
  return obj
}
