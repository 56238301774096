import { useCallback, useEffect, useState } from "react"
import { config } from "./config"
import { useRafTimer } from "./useRafTimer"

type Arguments =
  | {
      durationSeconds?: number
      /**
       * Only triggers if timer was played from a reset state
       */
      onTimesUp?: () => void
      playbackRate?: number
    }
  | undefined

export function useReplayTimer({
  durationSeconds = config.TIMER_LENGTH,
  onTimesUp = () => {},
  playbackRate = 1,
}: Arguments = {}) {
  const [isActive, setIsActive] = useState(false)
  const [ts, setTs] = useState(0)

  const millisecondsPassed = ts
  const secondsPassed = millisecondsPassed / 1000
  const isTimeUp = secondsPassed >= durationSeconds
  const secondsLeft = Math.ceil(durationSeconds - secondsPassed)

  const { start, stop } = useRafTimer(tsDelta => {
    setTs(prevTs => {
      const newTs = prevTs + tsDelta * playbackRate
      return newTs
    })
  })

  const stopTimer = useCallback(() => {
    stop()
    setIsActive(false)
  }, [stop])

  function resetTimer() {
    setIsDone(false)
    stop()
    setTs(0)
    setIsActive(false)
  }

  function startTimer() {
    if (isTimeUp) return
    if (!isActive) start()
    setIsActive(true)
  }

  function toggleTimer() {
    if (isActive) {
      stopTimer()
    } else {
      startTimer()
    }
  }

  function setTimerMilliseconds(ms: number) {
    stopTimer()
    setTs(ms)
  }

  // Stop ticking when time is up
  useEffect(() => {
    if (isTimeUp) stopTimer()
  }, [isTimeUp, stopTimer])

  // Trigger a callback when timer is done
  const [isDone, setIsDone] = useState(false)
  useEffect(() => {
    if (isDone) return
    if (isTimeUp) {
      setIsDone(true)
      onTimesUp()
    }
  }, [isDone, isTimeUp, onTimesUp])

  return {
    startTimer,
    stopTimer,
    toggleTimer,
    resetTimer,
    setTimerMilliseconds,
    isTimerStarted: isActive,
    isTimerActive: isActive,
    secondsPassed,
    millisecondsPassed,
    secondsLeft,
    isTimeUp,
    isDone,
  }
}
