import { Text } from "@chakra-ui/react"
import React from "react"
import { WordArray } from "../../types"
import { stats } from "../../wordUtils"

export default function Stats({
  durationSeconds,
  wordsToType,
  typedWords,
}: {
  durationSeconds: number
  wordsToType: WordArray
  typedWords: WordArray
}) {
  const { wpm, correctWords, wrongWords, wordAccuracy } = stats({
    durationSeconds,
    wantedWords: wordsToType,
    actualWords: typedWords,
  })

  return (
    <>
      <span>
        <Text
          lineHeight="1"
          fontWeight="extrabold"
          fontSize="5xl"
          display="inline"
        >
          {wpm}
        </Text>{" "}
        <span>WPM</span>
      </span>
      {/* <Divider
        height=".25px"
        backgroundColor="gray.500"
        borderColor="transparent"
      /> */}
      <div>
        <p>Correct words: {correctWords}</p>
        <p>Wrong words: {wrongWords}</p>
        <p>Word accuracy: {Math.round(wordAccuracy * 100)}%</p>
      </div>
    </>
  )
}
