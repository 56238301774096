// https://css-tricks.com/using-requestanimationframe-with-react-hooks/
import { useRef } from "react"

export function useRafTimer(cb: (deltaTs: number) => void) {
  const rafReq = useRef<number>(0)
  const prevTsRef = useRef<number>()

  const animate = (ts: number) => {
    if (prevTsRef.current !== undefined) {
      const deltaTs = ts - prevTsRef.current

      // Pass on a function to the setter of the state
      // to make sure we always have the latest state
      cb(deltaTs)
    }
    prevTsRef.current = ts
    rafReq.current = window.requestAnimationFrame(animate)
  }

  function stop() {
    prevTsRef.current = undefined
    window.cancelAnimationFrame(rafReq.current)
  }

  function start() {
    prevTsRef.current = undefined
    // Canceling in case this is called multiple times
    window.cancelAnimationFrame(rafReq.current)
    rafReq.current = window.requestAnimationFrame(animate)
  }

  return { start, stop }
}
