import { commonWords } from "./commonWords"
import { config } from "./config"
import { WordArray } from "./types"

/** `exceptWord` to pick any random word except for the one specified */
function randomWord({
  vocabSize = config.defaultPrefs.vocabSize,
  exceptWord,
}: {
  vocabSize?: number
  exceptWord?: string
}) {
  // How many of the top words are searched
  const topWords = vocabSize

  let word = ""
  do {
    const randomIndex = Math.floor(Math.random() * topWords)
    word = commonWords[randomIndex]
  } while (word === exceptWord)
  return word
}

export function randomWords(
  { vocabSize }: { vocabSize: number } = {
    vocabSize: config.defaultPrefs.vocabSize,
  }
) {
  const wordCount = config.RANDOM_WORD_COUNT

  let words = [...new Array(wordCount)]

  // Pick random words without repeats
  let prevWord = ""
  for (let i = 0; i < words.length; i++) {
    words[i] = randomWord({ vocabSize, exceptWord: prevWord })
    prevWord = words[i]
  }
  return words
}

function correctWords({
  wantedWords,
  actualWords,
}: {
  wantedWords: WordArray
  actualWords: WordArray
}) {
  let correctOnes: WordArray = []
  actualWords.forEach((_, i) => {
    if (actualWords[i] === wantedWords[i]) correctOnes.push(actualWords[i])
  })
  return correctOnes
}

export function wpm(correctWords: number, seconds: number) {
  return Math.round(correctWords / (seconds / 60))
}

export function stats({
  durationSeconds,
  wantedWords,
  actualWords,
}: {
  durationSeconds: number
  wantedWords: WordArray
  actualWords: WordArray
}) {
  const theCorrectWords = correctWords({ wantedWords, actualWords })

  return {
    wpm: wpm(theCorrectWords.length, durationSeconds),
    correctWords: theCorrectWords.length,
    wrongWords: actualWords.length - theCorrectWords.length,
    wordAccuracy: theCorrectWords.length / actualWords.length,
  }
}
