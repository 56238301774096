import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react"
import React, { FC } from "react"

export const Boxed: FC<BoxProps & { focused?: boolean }> = ({
  children,
  focused = false,
  ...rest
}) => {
  const borderColor = useColorModeValue("gray.200", "gray.600")
  return (
    <Box
      borderWidth={1}
      borderColor={focused ? "transparent" : borderColor}
      transitionProperty="all"
      transitionDuration="200ms"
      position="relative"
      rounded="xl"
      px={6}
      py={4}
      shadow={focused ? "outline" : "0px 0px 0px transparent"}
      {...rest}
    >
      {children}
    </Box>
  )
}
